<template>
  <div class="content">
    <div class="title">
      <!-- <h1 class="login_name">
                <span class="account">您现在使用的账号是：</span>
                <span class="plc">{{username}}</span>
            </h1>
            <h1 class="colleague">
                <span>系统已为您分配考核的同事有：</span>
                <p class="staff_name">zl320、柴文帅</p>
                <span>您还要从下面选择<span class="plc">{{num}}</span>位同事进行考核，请选择：</span>
            </h1> -->

      <div class="topic">
        <div class="use_account">
          <span>您现在是用的账号姓名是：<span style="color:#ee2e2e;">{{ username }}</span></span>
        </div>
        <div class="system">
          <div>系统已为您分配的考核同事有：</div>
          <div class="system_list">
            <span v-for="(item,index) in systemAssign" :key="index">{{ item.employee_realname }}<span v-show="index !== systemAssign.length-1">、</span></span>
          </div>
          <div class="select">
            <span>您还要从下面选择<span style="color:#ee2e2e;"> {{ showNum }} </span>位同事进行考核，请选择：</span>
          </div>
        </div>
      </div>

      <!-- <div class="topic">
        <p class="topic_title">考核填写说明：</p>
        <p class="topic_content">1、请对以下同事进行认真评选，请勾选出符合本条企业文化的同事；</p>
        <p class="topic_content">2、当所得分达到4分、5分时，需要有相应的事迹说明。</p>
        <p class="topic_num">您准备对以下<span class="plc">{{ showNum }}</span>位同事进行考核</p>
      </div> -->
    </div>
    <div class="name_content">
      <van-checkbox-group ref="checkboxGroup" v-model="checkList" :max="10" @change="changeStaffList">
        <!-- :disabled="isdis" -->
        <van-checkbox v-for="(v, i) in staffList[staffIndex]" :key="i" :name="v" icon-size="18px" class="check_box" shape="square" checked-color="#ee0a24">{{ v.employee_realname }}</van-checkbox>
      </van-checkbox-group>
    </div>
    <div v-if="obsolete" class="btn_box">
      <van-button type="danger" class="btn" @click="resetStaff">重新选</van-button>
      <van-button type="danger" class="btn" @click="changeStaff">换一换</van-button>
      <van-button type="danger" class="btn" @click="choose">选好了</van-button>
    </div>
  </div>
</template>

<script>
import { Toast } from 'vant'
import { getCulture, toChoseApi } from '@/api/assessment'
export default {
  data() {
    return {
      checkList: [],
      num: 10,
      showNum: 0,
      setting: [],
      username: '',
      staffList: [],
      staffIndex: 0,
      systemAssign: [],
      exam_date: '',
      isdis: false,
      obsolete: true,
      last_list: []
    }
  },
  watch: {
    '$route'(to, from) {
      this.showNum = this.num
      this.checkList = []
      this.isdis = false
      if (this.$route.query.isAgain == 1) {
        this.exam_date = this.$route.query.exam_date
        sessionStorage.setItem('DATE', this.exam_date)
        this.getData()
      }
    }
  },
  created() {
    this.exam_date = this.$route.query.exam_date
    sessionStorage.setItem('DATE', this.exam_date)
    this.getData()
  },
  methods: {
    // 选中人改变
    changeStaffList(val) {
      this.showNum = this.num - val.length
      if (this.showNum === 0) {
        this.isdis = true
      }
    },
    // 重新选
    resetStaff() {
      this.showNum = this.num
      this.checkList = []
      this.isdis = false
    },
    // 换一换
    changeStaff() {
      if (this.staffIndex >= this.staffList.length - 1) {
        this.staffIndex = 0
        return
      }
      this.staffIndex++
    },
    // 选好了
    choose() {
      if (!this.last_list.length) {
        if (this.checkList.length !== this.num) {
          Toast(`请选择其中${this.num}位同事`)
          return
        }
      }
      const params = {
        exam_date: this.exam_date,
        selected: JSON.stringify(this.checkList),
        name: 'answer',
        page: 1
      }
      //   console.log(this.checkList)
      toChoseApi(params).then((res) => {
        if (res.errNo === 0) {
          // console.log(res.result)
          sessionStorage.setItem('staffList', JSON.stringify(res.result))
          sessionStorage.setItem('isAgain', 1)
          this.$router.push({ path: '/assessment/cultrue', query: { exam_date: this.exam_date, num: this.num }})
        }
      })
    },
    getData() {
      const params = {
        exam_date: this.exam_date
      }
      getCulture(params).then((res) => {
        console.log(res)
        if (res.errNo === 0) {
          this.num = res.result.num
          this.showNum = res.result.num
          this.setting = res.result.setting
          this.username = res.result.user_name
          this.staffList = res.result.staff_list
          this.systemAssign = res.result.systemAssign
          sessionStorage.setItem('setting', JSON.stringify(res.result.setting))
          if (!this.num) {
            this.choose()
          }
          if (res.result.last_list) {
            this.last_list = res.result.last_list
            this.checkList = res.result.last_list
            if (this.$route.query.isAgain != 1) {
              this.choose()
            } else if (this.$route.query.isAgain == 1) {
              this.checkList = []
            }
          }
          this.staffList.forEach(item => {
            item.forEach(items => {
              if (items.case == 1) {
                this.checkList.push(items)
              }
            })
          })
          this.showNum = this.showNum - this.checkList.length
        } else if (res.errNo === 1001) {
          Toast('缺少评测时间')
        } else if (res.errNo === 1002) {
          this.obsolete = false
          Toast('已过评测最后时间')
        } else if (res.errNo === 1003) {
          Toast('仅限内部员工评测')
        } else if (res.errNo === 1004) {
          console.log('本人已答过当月文化考核')
          this.$router.push({
            path: '/assessment/assuccess'
          })
        } else if (res.errNo === 1005) {
          // 需要登录
          const url = window.location.href
          this.$router.push({ path: '/go_login', query: { redirect: url }})
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
// .topic {
//     font-size: 32px;
//     .topic_title {
//         padding-bottom: 30px;
//     }
//     .topic_content{
//         padding-left: 2em;
//     }
//     .topic_num {
//         padding-top: 30px;
//     }
// }
.content {
    padding: 20px;
    h1 {
        font-size: 30px;
        font-weight: 600;
    }
    .colleague {
        padding-top: 20px;
        .staff_name {
            margin: 10px 0;
            text-indent: 2em;
        }
    }
    .name_content {
        margin-top: 30px;
    }
    .btn_box{
        margin-top: 40px;
    }
}
.plc {
    color: #f00;
}

.use_account{
  font-size: 30px;
}
.system{
  margin-top: 10px;
  font-size: 30px;
}
.system_list{
  margin: 10px 0 0 10px;
  span{
    display: inline-block;
  }
}
.select{
  font-size: 30px;
  margin-top: 10px;
}
.btn_box {
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
}
.check_box{
  // background-color: aquamarine;
  // display: flex;
  // align-items: center;
  /deep/.van-checkbox__icon .van-icon{
    // width: 32px;
    // height: 32px;
    border-radius: 6px;
  }
}
.btn{
  border-radius: 10px;
  height: 80px;
}

/deep/ .van-checkbox-group {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
/deep/ .van-checkbox {
    width: 33%;
     margin-top: 30px;
}
/deep/ .van-checkbox__label {
    font-size: 28px;
}
/deep/ .van-button {
    width: 30%;
}
</style>
